/**
 * Generate frame and scan headers that can be prepended to the
 * RTP/JPEG data payload to produce a JPEG compressed image in
 * interchange format.
 *
 * For detailed information, check Appendix A of:
 * https://tools.ietf.org/html/rfc2435
 */

export function makeImageHeader() {
  return Buffer.from([0xff, 0xd8])
}

export function makeQuantHeader(precision: number, qTable: Buffer) {
  const lumSize = precision & 1 ? 128 : 64
  const chmSize = precision & 2 ? 128 : 64
  if (qTable.length !== lumSize + chmSize) {
    throw new Error('invalid quantization table')
  }
  const lumaPrefix = Buffer.from([0xff, 0xdb, 0, lumSize + 3, 0])
  const chromaPrefix = Buffer.from([0xff, 0xdb, 0, chmSize + 3, 1])
  return Buffer.concat([
    lumaPrefix,
    qTable.slice(0, lumSize),
    chromaPrefix,
    qTable.slice(lumSize),
  ])
}

export function makeFrameHeader(width: number, height: number, type: number) {
  return Buffer.from([
    0xff,
    0xc0, // SOF_0 (Start Of Frame)
    0,
    17,
    8,
    height >> 8,
    height,
    width >> 8,
    width,
    3,
    0,
    type === 0 ? 0x21 : 0x22,
    0,
    1,
    0x11,
    1,
    2,
    0x11,
    1,
  ])
}

// prettier-ignore
const LUM_DC_CODELENS = [
  0, 1, 5, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0
]
// prettier-ignore
const LUM_DC_SYMBOLS = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11
]
// prettier-ignore
const LUM_AC_CODELENS = [
  0, 2, 1, 3, 3, 2, 4, 3, 5, 5, 4, 4, 0, 0, 1, 0x7d
]
// prettier-ignore
const LUM_AC_SYMBOLS = [
  0x01, 0x02, 0x03, 0x00, 0x04, 0x11, 0x05, 0x12,
  0x21, 0x31, 0x41, 0x06, 0x13, 0x51, 0x61, 0x07,
  0x22, 0x71, 0x14, 0x32, 0x81, 0x91, 0xa1, 0x08,
  0x23, 0x42, 0xb1, 0xc1, 0x15, 0x52, 0xd1, 0xf0,
  0x24, 0x33, 0x62, 0x72, 0x82, 0x09, 0x0a, 0x16,
  0x17, 0x18, 0x19, 0x1a, 0x25, 0x26, 0x27, 0x28,
  0x29, 0x2a, 0x34, 0x35, 0x36, 0x37, 0x38, 0x39,
  0x3a, 0x43, 0x44, 0x45, 0x46, 0x47, 0x48, 0x49,
  0x4a, 0x53, 0x54, 0x55, 0x56, 0x57, 0x58, 0x59,
  0x5a, 0x63, 0x64, 0x65, 0x66, 0x67, 0x68, 0x69,
  0x6a, 0x73, 0x74, 0x75, 0x76, 0x77, 0x78, 0x79,
  0x7a, 0x83, 0x84, 0x85, 0x86, 0x87, 0x88, 0x89,
  0x8a, 0x92, 0x93, 0x94, 0x95, 0x96, 0x97, 0x98,
  0x99, 0x9a, 0xa2, 0xa3, 0xa4, 0xa5, 0xa6, 0xa7,
  0xa8, 0xa9, 0xaa, 0xb2, 0xb3, 0xb4, 0xb5, 0xb6,
  0xb7, 0xb8, 0xb9, 0xba, 0xc2, 0xc3, 0xc4, 0xc5,
  0xc6, 0xc7, 0xc8, 0xc9, 0xca, 0xd2, 0xd3, 0xd4,
  0xd5, 0xd6, 0xd7, 0xd8, 0xd9, 0xda, 0xe1, 0xe2,
  0xe3, 0xe4, 0xe5, 0xe6, 0xe7, 0xe8, 0xe9, 0xea,
  0xf1, 0xf2, 0xf3, 0xf4, 0xf5, 0xf6, 0xf7, 0xf8,
  0xf9, 0xfa
]
// prettier-ignore
const CHM_DC_CODELENS = [
  0, 3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0
]
// prettier-ignore
const CHM_DC_SYMBOLS = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11
]
// prettier-ignore
const CHM_AC_CODELENS = [
  0, 2, 1, 2, 4, 4, 3, 4, 7, 5, 4, 4, 0, 1, 2, 0x77
]
// prettier-ignore
const CHM_AC_SYMBOLS = [
  0x00, 0x01, 0x02, 0x03, 0x11, 0x04, 0x05, 0x21,
  0x31, 0x06, 0x12, 0x41, 0x51, 0x07, 0x61, 0x71,
  0x13, 0x22, 0x32, 0x81, 0x08, 0x14, 0x42, 0x91,
  0xa1, 0xb1, 0xc1, 0x09, 0x23, 0x33, 0x52, 0xf0,
  0x15, 0x62, 0x72, 0xd1, 0x0a, 0x16, 0x24, 0x34,
  0xe1, 0x25, 0xf1, 0x17, 0x18, 0x19, 0x1a, 0x26,
  0x27, 0x28, 0x29, 0x2a, 0x35, 0x36, 0x37, 0x38,
  0x39, 0x3a, 0x43, 0x44, 0x45, 0x46, 0x47, 0x48,
  0x49, 0x4a, 0x53, 0x54, 0x55, 0x56, 0x57, 0x58,
  0x59, 0x5a, 0x63, 0x64, 0x65, 0x66, 0x67, 0x68,
  0x69, 0x6a, 0x73, 0x74, 0x75, 0x76, 0x77, 0x78,
  0x79, 0x7a, 0x82, 0x83, 0x84, 0x85, 0x86, 0x87,
  0x88, 0x89, 0x8a, 0x92, 0x93, 0x94, 0x95, 0x96,
  0x97, 0x98, 0x99, 0x9a, 0xa2, 0xa3, 0xa4, 0xa5,
  0xa6, 0xa7, 0xa8, 0xa9, 0xaa, 0xb2, 0xb3, 0xb4,
  0xb5, 0xb6, 0xb7, 0xb8, 0xb9, 0xba, 0xc2, 0xc3,
  0xc4, 0xc5, 0xc6, 0xc7, 0xc8, 0xc9, 0xca, 0xd2,
  0xd3, 0xd4, 0xd5, 0xd6, 0xd7, 0xd8, 0xd9, 0xda,
  0xe2, 0xe3, 0xe4, 0xe5, 0xe6, 0xe7, 0xe8, 0xe9,
  0xea, 0xf2, 0xf3, 0xf4, 0xf5, 0xf6, 0xf7, 0xf8,
  0xf9, 0xfa
]

export function makeHuffmanHeader() {
  const LUM_DC_BUFFER = [
    [
      0xff,
      0xc4,
      0,
      3 + LUM_DC_CODELENS.length + LUM_DC_SYMBOLS.length,
      (0 << 4) | 0,
    ],
    LUM_DC_CODELENS,
    LUM_DC_SYMBOLS,
  ]

  const LUM_AC_BUFFER = [
    [
      0xff,
      0xc4,
      0,
      3 + LUM_AC_CODELENS.length + LUM_AC_SYMBOLS.length,
      (1 << 4) | 0,
    ],
    LUM_AC_CODELENS,
    LUM_AC_SYMBOLS,
  ]

  const CHM_DC_BUFFER = [
    [
      0xff,
      0xc4,
      0,
      3 + CHM_DC_CODELENS.length + CHM_DC_SYMBOLS.length,
      (0 << 4) | 1,
    ],
    CHM_DC_CODELENS,
    CHM_DC_SYMBOLS,
  ]

  const CHM_AC_BUFFER = [
    [
      0xff,
      0xc4,
      0,
      3 + CHM_AC_CODELENS.length + CHM_AC_SYMBOLS.length,
      (1 << 4) | 1,
    ],
    CHM_AC_CODELENS,
    CHM_AC_SYMBOLS,
  ]

  return Buffer.concat([
    ...LUM_DC_BUFFER.map(Buffer.from),
    ...LUM_AC_BUFFER.map(Buffer.from),
    ...CHM_DC_BUFFER.map(Buffer.from),
    ...CHM_AC_BUFFER.map(Buffer.from),
  ])
}

export function makeScanHeader() {
  return Buffer.from([
    0xff,
    0xda, // SOS (Start Of Scan)
    0,
    12,
    3,
    0,
    0,
    1,
    0x11,
    2,
    0x11,
    0,
    63,
    0,
  ])
}

export function makeDRIHeader(dri: number) {
  return Buffer.from([0xff, 0xdd, 0x00, 4, dri >> 8, dri & 0xff])
}
